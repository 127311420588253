import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import React from "react";

const Authors = (props: { author: any, readonly?: any, addElement?: any }) => {

    let author = props.author?.filter((item: any) => item.type === 2) ?? undefined;

    const deleteElement = (e: any) => {
        let element = e.target.parentElement.parentElement;
        element.remove();
    };

    // Container für Autoren-Einträge
    let authorContainer = [];

    if (Array.isArray(author) && author.length === 0) {
        authorContainer.push(
            <InputGroup key="0" className="mb-3 inputGroupExtra form-control">
                <InputGroup.Text>
                    1
                </InputGroup.Text>
                <Form.Control
                    readOnly={props.readonly !== undefined && props.readonly !== null ? props.readonly : true}
                    name={'Authors0'}
                    placeholder="Host email adress"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={'inputNoBorder'}
                    type={'email'}
                    id={'Authors'}
                />
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                 onClick={() => props.addElement(false, '12', 'Authors', '', true)}>
                    <span className="material-icons">add</span>
                </InputGroup.Text>
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}>
                    <span className="material-symbols-outlined" style={{ color: '#317391' }}
                          onClick={(e) => deleteElement(e)}>
                        delete
                    </span>
                </InputGroup.Text>
            </InputGroup>
        )
    } else if (author !== undefined) {
        for (let a = 0; a < author.length; a++) {
            authorContainer.push(
                <InputGroup key={a} className="mb-3 inputGroupExtra form-control">
                    <InputGroup.Text>
                        {a + 1}
                    </InputGroup.Text>
                    <Form.Control
                        readOnly={props.readonly !== undefined && props.readonly !== null ? props.readonly : true}
                        name={'Authors' + a}
                        placeholder="Host email adress"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        className={''}
                        defaultValue={author[a].email}
                        type={'email'}
                    />
                    <InputGroup.Text>
                        {author[a].hostId === 21 && <span className="text-warning">Please register the user!</span>}
                        {author[a].hostId !== 21 && <span className="text-success">User recognized</span>}
                    </InputGroup.Text>
                    <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                     onClick={() => props.addElement(false, '12', 'Authors', '', true)}>
                        <span className="material-icons">add</span>
                    </InputGroup.Text>
                    <InputGroup.Text id="basic-addon2" className={'IconPlus'}>
                        <span className="material-symbols-outlined" style={{ color: '#317391' }}
                              onClick={(e) => deleteElement(e)}>
                            delete
                        </span>
                    </InputGroup.Text>
                </InputGroup>
            );
        }
    } else {
        authorContainer.push(
            <InputGroup key="0" className="mb-3 inputGroupExtra form-control">
                <InputGroup.Text>
                    1
                </InputGroup.Text>
                <Form.Control
                    readOnly={props.readonly !== undefined && props.readonly !== null ? props.readonly : true}
                    name={'Authors0'}
                    placeholder="Host email adress"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={'inputNoBorder'}
                    type={'email'}
                    id={'Authors'}
                />
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                 onClick={() => props.addElement(false, '12', 'Authors', '', true)}>
                    <span className="material-icons">add</span>
                </InputGroup.Text>
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}>
                    <span className="material-symbols-outlined" style={{ color: '#317391' }}
                          onClick={(e) => deleteElement(e)}>
                        delete
                    </span>
                </InputGroup.Text>
            </InputGroup>
        );
    }

    return (
        <Row id="Authors">
            <div className="col-12">
                {authorContainer}
            </div>
        </Row>
    );
};

export default Authors;
