import {IntlMessageValue, UniForm} from "../../../../../@WUM/core/Elements";
import UniSelect from "../../../../../@WUM/core/Elements/Form/Elements/Select";

export const WorkshopSelect = (props: { WorkshopList: any, defaultValue?: any, readOnly?: boolean, store: any}) => {
    console.log(props.WorkshopList);
    const transformedItems = props.WorkshopList.map((item: {
        id: { toString: () => any; };
        title: any;
        workshopTime: any;
    }) => ({
        value: item.id.toString(),
        text: `${item.title} ${item.workshopTime}`,
    }));

    if (props.WorkshopList.length > 0) {
        return (
            <>
                <UniForm.FormGroup>
                    <UniForm.Label text={IntlMessageValue('de', 'icy.session.workshop.termine.expl', props.store)}/>
                    <UniSelect items={transformedItems} freeValue={true} freeValueLabel={"Please choose"}
                               name={"workshopSelect"} defaultValue={props.defaultValue} disabled={props.readOnly} required={true}/>
                </UniForm.FormGroup>
            </>
        )
    } else {
        return (<>Wait</>)
    }

}