import {useNavigate, useParams} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup'
import '../css/dashboard.css'
import {useSelector} from "react-redux";
import ReactDOM from "react-dom";
import {
    ResponseLevel,
    ResponsePresentationType,
    ResponseSession,
    ResponseAbstractSingle, ResponseAllWorkshops
} from "../../API/request/abstract";
import {SpinnerJSX} from '../../../../../@WUM/core/component/const/Spinner';
import Badge from "react-bootstrap/Badge";
import {ApiURL} from "../../../../../@WUM/core/component/const/ApiURL";
// @ts-ignore
import {Notification} from "create-notifications/notfication";
import {btn_back} from "../../../../../@WUM/core/component/const/btn_back";
import Authors from "../helper/authors";
import React, {useState} from "react";
import {WorkshopSelect} from "../helper/workshopSelect";
import {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";

const AbstractDetail = (props: { value: any }) => {
    const {id} = useParams<{ id: string }>();
    const Store = useSelector((state: any) => state)
    const [Author, setAuthor] = useState(0)
    let authorint = Author;
    const Level = ResponseLevel();
    const response = ResponseAbstractSingle(id, setAuthor)
    const PresentationType = ResponsePresentationType();
    const AllWorkshops = ResponseAllWorkshops("2")
    const history = useNavigate();
    const back = btn_back(history, "common.back", "btnBack", Store)
    const Session = ResponseSession();
    const send = (e: any, state: any) => {
        e.preventDefault();
        let data = {
            state: state,
            response: (document.getElementById('Response') as HTMLTextAreaElement).value
        };
        let root = ApiURL();
        let raw = JSON.stringify(data);
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
            },
            body: raw,
        };


        fetch(root + "Conference/CfS/icy/response/" + id + "/", requestOptions)
            .then(response => response.text())
            .then(result => afterSend('success'))
            .catch(error => afterSend('error'));
    }

    const afterSend = (state: any) => {
        if (state === 'success') {
            Notification({
                reload: true,
                type: 'success',
                mode: 'classic',
                title: {text: 'Success'},
                text: {text: 'Saved successfully'},
                insert: 'center right',
                duration: 4
            })
        } else if (state === 'error') {
            Notification({
                reload: true,
                type: 'danger',
                mode: 'classic',
                title: {text: 'Error'},
                text: {text: 'Sorry, an error has occurred'},
                insert: 'center right',
                duration: 4
            })
        }
    }

    // remove hosts
    const deleteElement = (e: any) => {
        let element = e.target.parentElement.parentElement
        element.remove();
    }

    const addElement = (row: any = false, col: any, id: any, addClass: any = '') => {
        let counter;
        if (id === 'Authors') {
            authorint++;
            counter = authorint;
        }
        if (row === true) {
            let newElement = (<>
                <div className={''}><InputGroup className={"mb-3 inputGroupExtra form-control " + addClass}>
                    <Form.Control
                        name={id + '' + counter}
                        placeholder="Lorem Ipsum"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        className={'inputNoBorder'}
                    />
                    <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                     onClick={() => addElement(row, col, id, addClass)}>
                                <span className="material-icons">
                                    add
                                </span>
                    </InputGroup.Text>
                    <InputGroup.Text id="basic-addon2" className={'IconPlus'}>
                    <span
                        className="material-symbols-outlined" style={{color: '#317391'}}
                        onClick={(e) => deleteElement(e)}>
                    delete
                    </span>
                    </InputGroup.Text>

                </InputGroup></div>
            </>);

            let temp = document.createElement('div');
            temp.classList.add('row')
            ReactDOM.render(newElement, temp);
            let ele = document.getElementById(id);
            if (ele !== null) {
                ele.appendChild(temp)
            }
        } else {
            let newElement = (<><InputGroup className={"mb-3 inputGroupExtra form-control " + addClass}>
                <Form.Control
                    name={id + '' + counter}
                    placeholder="Lorem Ipsum"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={'inputNoBorder'}
                />
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                 onClick={() => addElement(row, col, id, addClass)}>
                                <span className="material-icons">
                                    add
                                </span>
                </InputGroup.Text>
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}>
                 <span
                     className="material-symbols-outlined" style={{color: '#317391'}} onClick={(e) => deleteElement(e)}>
                    delete
                    </span>
                </InputGroup.Text>
            </InputGroup></>);
            let temp = document.createElement('div');
            temp.classList.add('col-' + col)
            ReactDOM.render(newElement, temp);
            let ele = document.getElementById(id);
            if (ele !== null) {
                ele.appendChild(temp)
            }
        }

    }

    if (Level.length > 0 && PresentationType.length > 0 && Session.length > 0 && response !== undefined && AllWorkshops.length > 0) {
        const subject = encodeURIComponent("Support Request");
        const body = encodeURIComponent(`ID: ${response.id}\nEmail: ${response.user.email}`);


        let optionLevel = [];

        let optionPresentationType = [];

        let optionSession = [];

        for (let l = 0; l < Level.length; l++) {
            optionLevel.push(
                <>
                    <option value={Level[l].id}>{Level[l].type}</option>
                </>
            )
        }

        for (let l = 0; l < PresentationType.length; l++) {
            optionPresentationType.push(
                <>
                    <option value={PresentationType[l].id}>{PresentationType[l].type}</option>
                </>
            )
        }

        for (let l = 0; l < Session.length; l++) {
            optionSession.push(
                <>
                    <option value={Session[l].id}>{Session[l].title}</option>
                </>
            )
        }


        let badgeColor;
        let badgeText;
        let state = [
            {name: 'submitted', color: 'danger'},
            {name: 'Icymare: Declined ', color: 'danger'},
            {name: 'Icymare: Rework by user', color: 'warning'},
            {name: 'Icymare: Passed to Host', color: 'warning'},
            {name: 'Host: Not suitable for session', color: 'danger'},
            {name: 'Host: Revision by user', color: 'warning'},
            {name: 'Icymare: Final acceptance', color: 'success'}]

        for (let s = 0; s < state.length; s++) {
            if (state[s].name === response.state) {
                badgeColor = state[s].color;
                badgeText = state[s].name;
            }
        }


        return (<>
            <Row className={''}>
                <Row>
                    <Col xs={4} md={4} lg={4} style={{
                        background: 'transparent linear-gradient(270deg, #0CB4CE 0%, #317391 100%) 0% 0% no-repeat padding-box',
                        color: 'white',
                        maxHeight: '840px'
                    }}>
                        <br/>
                        <p className={'text-center'}><img
                            src={'https://cdn.wum-solution.de//icymareKonfDev/2023/12/18/jU/ICYMARE-Logo-wide-screen.png'}/>
                        </p>
                        <br/>
                        <h2>{IntlMessageValue('de', 'icy.session.submission.text', Store)}</h2>
                        <p><img
                            src={'/assets/ICYMARE-2025-BREMERHAVEN_CallForSessions.jpg'}/>
                        </p>
                        <br/>
                        <p></p>
                        <p className={'text-center'}>State: <Badge bg={badgeColor}> {badgeText}
                        </Badge></p>
                    </Col>
                    <Col style={{
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        maxHeight: '840px',
                        overflowX: 'hidden',
                        overflowY: 'scroll'
                    }}> <Form id={'form01'}>
                        <Row><h3>{IntlMessageValue('de', 'icy.session.holder.main', Store)}</h3></Row><Row>
                        {/*hidden field for year*/}
                        <Form.Control type="text" name={'year'} placeholder="Enter Title" defaultValue={response.year}
                                      hidden={true}/>

                        <Col>
                            <Form.Group className="mb-3" controlId="Title">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" name={'academic_title'} placeholder="Enter Title"
                                              readOnly={true} defaultValue={response.academic_title}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="FirstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" name={'FirstName'} placeholder="Enter first name"
                                              readOnly={true} defaultValue={response.user.first_name}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="LastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" name={'LastName'} placeholder="Enter last name"
                                              readOnly={true} defaultValue={response.user.last_name}/>
                            </Form.Group>
                        </Col>
                    </Row>
                        <Row>

                            <Col>
                                <Form.Group className="mb-3" controlId="Emailaddress">
                                    <Form.Label>Email address:</Form.Label>
                                    <Form.Control type="email" name={'Emailaddress'} placeholder="Enter email adress"
                                                  readOnly={true} defaultValue={response.user.email}/>
                                </Form.Group>
                            </Col>
                            <Col className={"my-auto"}>
                                <div className={"border rounded p-1 border-dark"}>{IntlMessageValue('de', 'icy.name.changes', Store)}
                                    <a href={`mailto:support@icymare.com?subject=${subject}&body=${body}`}>support@icymare.com</a>;
                                </div>
                            </Col>
                        </Row>
                        <Row><Col><Form.Group className="mb-3" controlId="Mainaffiliation">
                            <Form.Label>Main affiliation</Form.Label>
                            <Form.Control type="text" name={'Mainaffiliation'} readOnly={true}
                                          placeholder="Enter main affiliation" defaultValue={response.mainAffiliation}/>
                        </Form.Group></Col></Row>


                        <Row><h3>Education Level</h3>
                            <p>{IntlMessageValue('de', 'icy.session.ecr.expl', Store)}</p>
                        </Row> <Row>

                        <Col>
                            <Form.Label>Level</Form.Label>
                            <Form.Select aria-label="Default select example" name={'Level'} disabled={true}
                                         defaultValue={response.educationLevel.levelId}>
                                {optionLevel}
                            </Form.Select>
                        </Col>
                        <Col><Form.Group className="mb-3" controlId="Year">
                            <Form.Label>Year</Form.Label>
                            <Form.Control type="text" name={'Year'} placeholder="Enter Year" readOnly={true}
                                          defaultValue={response.educationLevel.year}/>
                        </Form.Group></Col>
                    </Row>

{/*
                        <Row><h3>Sessions</h3>
                            <p>Please choose the session in which you want to present your work and the type of
                                presentation via the drop-down list below:</p>
                        </Row>
*/}
                        {/*<Row>
                            <Col>
                                <Form.Select aria-label="Default select example" name={'Sessions'} defaultValue={response.confSession.id}>
                                    {optionSession}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Select aria-label="Default select example" name={'PresentationType'} disabled={true} defaultValue={response.presentationTypeId}>
                                    {optionPresentationType}
                                </Form.Select>
                            </Col>
                        </Row>*/}
                        <br/>
                        <Row>
                            <Form.Group className="mb-3" controlId="PresentationTitle">
                                <Form.Label><h3>{IntlMessageValue('de', 'icy.session.title', Store)}</h3></Form.Label>
                                {/*<Form.Control type="text" name={'title'} placeholder="Enter Session title" readOnly={true} defaultValue={response.title}/>*/}
                                <p dangerouslySetInnerHTML={{__html: response.title}} style={{
                                    backgroundColor: '#e9ecef',
                                    opacity: '1',
                                    minHeight: '60px',
                                    borderRadius: '10px',
                                    padding: '10px',
                                    overflowY: 'scroll',
                                    resize: 'both'
                                }}/>
                            </Form.Group>
                        </Row>

                        <Row><h3>{IntlMessageValue('de', 'icy.session.additional.hosts.headline', Store)}</h3>
                            <p>{IntlMessageValue('de', 'icy.session.additional.hosts', Store)}</p>
                        </Row>
                        <Authors author={response.authors} readonly={false} addElement={addElement}/>
                        <br/>
{/*
                        <p>Please fill in all affiliations of the contributing authors according to the numbers stated
                            above. Use the + for adding more arrays (click on the field).</p>
                        <Affiliations affiliations={response.affiliates} newlabel={'Host'}/>
*/}
                        <br/>
                        <Row>
                            <h3>
                                {IntlMessageValue('de', 'icy.session.workshop.headline', Store)}
                            </h3>
                        </Row>

                        <WorkshopSelect WorkshopList={AllWorkshops} defaultValue={response.workshopSelect} readOnly={true} store={Store}/>
                        <br/>
                        <Row><h3>Social Media Handles</h3>
                            <p>Please provide us with your social media handles (“@...”) to enable us to tag you:</p>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="Twitter">
                                    <Form.Label>Twitter</Form.Label>
                                    <Form.Control type="text" name={'Twitter'} readOnly={true}
                                                  placeholder="Enter Twitter account"
                                                  defaultValue={response.socialMediaInstitution[0].Twitter}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="Instagram">
                                    <Form.Label>Instagram</Form.Label>
                                    <Form.Control type="text" name={'Instagram'} readOnly={true}
                                                  placeholder="Enter Instagram account"
                                                  defaultValue={response.socialMediaInstitution[0].Instagram}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="Facebook">
                                    <Form.Label>Facebook</Form.Label>
                                    <Form.Control type="text" name={'Facebook'} readOnly={true}
                                                  placeholder="Enter Facebook account"
                                                  defaultValue={response.socialMediaInstitution[0].Facebook}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="LinkedIn">
                                    <Form.Label>LinkedIn</Form.Label>
                                    <Form.Control type="text" name={'LinkedIn'} readOnly={true}
                                                  placeholder="Enter LinkedIn account"
                                                  defaultValue={response.socialMediaInstitution[0].LinkedIn}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="Mastodon">
                                    <Form.Label>Mastodon</Form.Label>
                                    <Form.Control type="text" name={'Mastodon'} readOnly={true} placeholder="Enter Mastodon account" defaultValue={response.socialMediaInstitution[0].Mastodon}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="Bluesky">
                                    <Form.Label>Bluesky</Form.Label>
                                    <Form.Control type="text" name={'Bluesky'} readOnly={true} placeholder="Enter Bluesky account" defaultValue={response.socialMediaInstitution[0].Bluesky}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row><h4>Social Media Handles (Institution)</h4>
                            <p>Please provide us with the social media handles of your first affiliation to enable us to
                                tag the institution (preferably “@...”, otherwise also “#...” is possible):</p>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionTwitter">
                                    <Form.Label>Twitter</Form.Label>
                                    <Form.Control type="text" name={'InstitutionTwitter'} readOnly={true}
                                                  placeholder="Enter Twitter account"
                                                  defaultValue={response.socialMediaInstitution[0].InstitutionTwitter}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionInstagram">
                                    <Form.Label>Instagram</Form.Label>
                                    <Form.Control type="text" name={'InstitutionInstagram'} readOnly={true}
                                                  placeholder="Enter Instagram account"
                                                  defaultValue={response.socialMediaInstitution[0].InstitutionInstagram}/>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionFacebook">
                                    <Form.Label>Facebook</Form.Label>
                                    <Form.Control type="text" name={'InstitutionFacebook'} readOnly={true}
                                                  placeholder="Enter Facebook account"
                                                  defaultValue={response.socialMediaInstitution[0].InstitutionFacebook}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionLinkedIn">
                                    <Form.Label>LinkedIn</Form.Label>
                                    <Form.Control type="text" name={'InstitutionLinkedIn'} readOnly={true}
                                                  placeholder="Enter LinkedIn account"
                                                  defaultValue={response.socialMediaInstitution[0].InstitutionLinkedIn}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionMastodon">
                                    <Form.Label>Mastodon</Form.Label>
                                    <Form.Control type="text" name={'InstitutionMastodon'} readOnly={true} placeholder="Enter Mastodon account" defaultValue={response.socialMediaInstitution[0].InstitutionMastodon}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="InstitutionBluesky">
                                    <Form.Label>Bluesky</Form.Label>
                                    <Form.Control type="text" name={'InstitutionBluesky'} readOnly={true} placeholder="Enter Bluesky account" defaultValue={response.socialMediaInstitution[0].InstitutionBluesky}/>
                                </Form.Group>
                            </Col>
                        </Row>


                        <Row><h3>Abstract</h3>
                            <p>{IntlMessageValue('de', 'icy.session.abstract.expl', Store)}</p>
                        </Row>
                        <Row>
                            <Form.Group className="mb-3" controlId="Abstract">
                            {/*<Form.Control as="textarea" name={'Abstract'} readOnly={true} rows={5} defaultValue={response.abstract}/>*/}
                                <p dangerouslySetInnerHTML={{__html: response.abstract}} style={{
                                    backgroundColor: '#e9ecef',
                                    opacity: '1',
                                    minHeight: '135px',
                                    borderRadius: '10px',
                                    padding: '10px',
                                    overflowY: 'scroll',
                                    resize: 'both'
                                }}/>
                            </Form.Group>
                        </Row>

                        <Row>
                            <h3>{IntlMessageValue('de', 'icy.session.mandatory.documents', Store)}</h3>
                            {response.media1 !== null && response.media1 !== undefined
                                ?
                                <Col>
                                    <Form.Label>CV</Form.Label>
                                    <br/>
                                    <object
                                        data={SecureRequest(response.media1.cdn_url)}
                                        width={'100%'}
                                        height={'400px'}
                                        aria-label={'pdf'}
                                        className={'mb-3'}
                                    />
                                </Col>
                                :
                                <> No document found
                                </>

                            }
                            {response.media2 !== null && response.media2 !== undefined
                                ?
                                <Col>
                                    <Form.Label>{IntlMessageValue('de', 'icy.session.letter.headline', Store)}</Form.Label>
                                    <br/>
                                    <object
                                        data={SecureRequest(response.media2.cdn_url)}
                                        width={'100%'}
                                        height={'400px'}
                                        aria-label={'pdf'}
                                        className={'mb-3'}
                                    />
                                </Col>
                                :
                                <> No document found
                                </>

                            }
                        </Row>
                        <Row>
                            <Col>
                                <br/>
                                <Form.Group className="mb-3" controlId="mending">
                                    <Form.Label>Mending Message</Form.Label>
                                    <Form.Control as="textarea" name={'Response'} id={'Response'} rows={5}
                                                  defaultValue={response.icyResponse}/>
                                </Form.Group>
                                <br/>
                            </Col>

                        </Row>
                        <Row>
                            <Col className={'text-center'}>{back}</Col>
                            <Col className={'text-center'}><Button variant="danger"
                                                                   onClick={(e) => send(e, '1')}>Declined</Button></Col>
                            <Col className={'text-center'}><Button variant="warning" onClick={(e) => send(e, '2')}>Rework
                                by user</Button></Col>
                            <Col className={'text-center'}><Button variant="success" onClick={(e) => send(e, '3')}>Final
                                acceptance</Button></Col>

                        </Row>
                        <Row>
                            <Col>
                                <br/> <br/>
                            </Col>
                        </Row>
                    </Form></Col>
                </Row>

            </Row>
        </>)
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }
}


export default AbstractDetail;


export const SecureRequest = (mediaUrl: any) => {

    let client = process.env.REACT_APP_CDN_CLIENT_KEY;
    let password = process.env.REACT_APP_CDN_PASSWORT;
    let url = btoa(mediaUrl.replace(process.env.REACT_APP_CDN_URL + '/', ''))


    return process.env.REACT_APP_CDN_URL + '/api/Media/' + url + '/?cdn_secret_access_key=' + password + '&cdn_access_client=' + client

}
