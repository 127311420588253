import React, {useState
} from 'react';
import {useNavigate,
    useParams
} from 'react-router-dom';
import { ContentSwitch, SpinnerJSX, SearchJSX } from '../../../../../@WUM/core/Elements/index';
import {useSelector} from "react-redux";
import { ResponseAbstractByUser} from '../../API/request/abstract';
import configuration from '../configuration/Page/overview';

const AbtractOverviewUser = (value:any) => {

    const {page,deleteID} = useParams<{page: string,deleteID: string}>();
    let limit: any;
    if (typeof page === "string") {
        limit = (parseFloat(page) * 10) - 10;
    }

    const Store = useSelector((state: any) => state)
    const history = useNavigate();
    /**
     * Neuladen der Seite
     */
    const routeChange = (paths:string) => {
        history(-1);
    };

    if(deleteID) {

        routeChange('/SessionHost/overview/1')
    }
    const debug = (page: string | undefined,modalShow: boolean, modalData: any[]) => {
        return;
    }
    const destination = 'SessionHost';
    const namespace_search = "SessionHostUser_searchValue";
    const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
    const [search, setSearch] = useState<boolean>(false)
    const response = ResponseAbstractByUser(limit,searchValue,Store.user.id);
    const [modalShow, setModalShow] = React.useState(false);
    const [modalData, setModalData] = React.useState([]);
    debug(page,modalShow,modalData)
    const [mode, setMode] = useState('cart');


    const SearchConfig = [{
        namespace:namespace_search,
        destination: destination,
        input:[
            {id:'search_name',translation: 'Search for title...',param: 'title',method: 'like'}

        ],
        button: ['Search','Delete Search','Submit Abstract']
    }]


    const CardsConfiguration = {
        'data': {
            'ressource': response,
            'img': '',
            'status': 'state',
            'statusindi': true,
            'statusMapping': [
                {name: 'submitted',color:'danger'},
                {name: 'Icymare: Declined ',color:'danger'},
                {name: 'Icymare: Rework by user',color:'warning'},
                {name: 'Icymare: Passed to Host',color:'warning'},
                {name: 'Host: Not suitable for session',color:'danger'},
                {name: 'Host: Revision by user',color:'warning'},
                {name: 'Icymare: Final acceptance',color:'success'}
            ],
            'link': 'translation.de.slug',
            'prelink': '/magazin/[param]/',
            'prelinksearch': 'translation.de.slug',
            'title': 'title',
            'descriptionShow': false,
            'description': 'title',
            'showauthor': true,
            'author_firstname': 'user.first_name',
            'author_lastname': 'user.last_name',
            'router': 'id',
            'preroute': '/SessionHost/DetailUser/',
            'edit': 'Show Abstract',
            'editOnly': true,
            'delete': false,
            'deleteLink': '/SessionHost/Delete/'+page+'/[ID]',
            'Dontvisited': true,
        }
    }


    if (response.length > 0) {
        return (
            <> <SearchJSX setSearchValue={setSearchValue} Store={Store} SearchConfig={SearchConfig} />
                { <ContentSwitch pagenew={'new'} destinationnew={'SessionHost'} tonew={'new'} text={'Show Abstract'}
                                 response={response} CardsConfiguration={CardsConfiguration} mode={mode}
                                 Configuration={configuration(response,'User')} setMode={setMode}  destination={destination} page={page}
                                 to={'overviewUser'} setSearchValues={setSearchValue} setSearchs={setSearch} search={false}
                                 modalShow={modalShow} setModalShow={setModalShow} modalData={modalData} setModalData={setModalData} SearchParam={'?translation.title'}
                                 searchfield={false} allTaxon={[]} _handleKeyFilters={[]} _handleEnter={[]} filter={true} stop={false} Store={Store}/>}
            </>

        )
    } else {
        localStorage.setItem('searchEnterInput','');
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }

};



export default AbtractOverviewUser;



