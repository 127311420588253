import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup'
import '../css/dashboard.css'
import {useSelector} from "react-redux";
import ReactDOM from "react-dom";
import {
    ResponseAllWorkshops,
    ResponseLevel,
    ResponsePresentationType
} from "../../API/request/abstract";
import {SpinnerJSX} from '../../../../../@WUM/core/component/const/Spinner';
import {ApiURL} from "../../../../../@WUM/core/component/const/ApiURL";
// @ts-ignore
import {Notification} from "create-notifications/notfication";
import {useNavigate} from "react-router-dom";
import {btn_back} from "../../../../../@WUM/core/component/const/btn_back";
import SunEditor from "suneditor-react";
import token from "../../../../ticketsystem/modules/components/Token";
import React, {useState} from "react";
import {WorkshopSelect} from "../helper/workshopSelect";
import {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";
import Authors from "../helper/authors";

const AbstractNew = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)
    const [Author, setAuthor] = useState(0)
    let authorint = Author;
    const Level = ResponseLevel();
    const history = useNavigate();
    const back = btn_back(history, "icy.back", "btnBack", Store)
    const PresentationType = ResponsePresentationType();
    const [valueAbstract, setValueAbstract] = useState("");
    const [valueSessionTitle, setValueSessionTitle] = useState("");
    const AllWorkshops = ResponseAllWorkshops("2")

    const setEditorAbstractContent = (value:any) =>{
        if(value === '<p><br></p>') {
            setValueAbstract('')
        } else{
            setValueAbstract(value)
        }

    }
    const setEditorPresentationTitleContent = (value:any) =>{
        if(value === '<p><br></p>') {
            setValueSessionTitle('')
        } else{
            setValueSessionTitle(value)
        }

    }

    const SunEditorConfig = () => {
        return [
            // Default
            ['undo', 'redo', 'italic', 'subscript', 'superscript', 'removeFormat', 'fullScreen'],
        ]
    }
    const send = (e: any) => {
        e.preventDefault();

        // Button sofort deaktivieren
        const button = document.getElementById('sendButton') as HTMLButtonElement;
        if (button) {
            button.setAttribute('disabled', 'true');
            button.style.background = 'gray';
            button.style.cursor = 'not-allowed'; // Ändert den Mauszeiger für bessere Benutzererfahrung
        }

        // Nach 5 Sekunden Button wieder aktivieren
        setTimeout(() => {
            if (button) {
                button.removeAttribute('disabled');
                button.style.background = '#307593'; // Ursprüngliche Farbe
                button.style.cursor = 'pointer'; // Zurück zum Zeiger
            }
        }, 5000);

        let newFile1: any;

        let newFile2: any;

        let formfile = (document.getElementById('fileuploadAttachment1') as HTMLInputElement).files
        let allowedType = [{ type: 'application/pdf', size: 5000000 }];
        if (formfile !== null) {
            for (let f = 0; f < formfile?.length; f++) {
                let formDatas = new FormData()
                allowedType = [
                    {type: 'application/pdf', size: 5000000}
                ]
                let checkType = 0
                let checkSize = 0
                if (allowedType !== undefined && allowedType.length > 0) {
                    for (let type = 0; type < allowedType.length; type++) {
                        // Überprüfung des Dateiformats
                        if (allowedType[type].type === formfile[f].type) {
                            checkType = checkType + 1;
                        }
                        // Überprüfung der Dateigröße
                        if (formfile[f].size <= allowedType[type].size) {  // Fixiere den Vergleich (kleiner gleich)
                            checkSize = checkSize + 1;
                        }
                    }
                }

                // Fehlerbehandlung bei falschem Format oder Größe
                if (checkType < 1) {
                    alert(`Invalid file format: ${formfile[f].name}. Only PDF files are allowed.`);
                    return; // Funktion abbrechen
                }
                if (checkSize < 1) {
                    alert(`File too large: ${formfile[f].name}. Maximum allowed size is 5MB.`);
                    return; // Funktion abbrechen
                }

                formDatas.append('customer', process.env.REACT_APP_CDN_CLIENT as string)
                formDatas.append('context', 'secure')
                // @ts-ignore
                formDatas.append(`file${0}`, formfile[f])
                var requestOptions = {
                    method: 'POST',
                    body: formDatas
                }
                let link
                if (process.env.REACT_APP_MODE === 'LOCAL') {
                    link = process.env.REACT_APP_CDN_API_URL_LOCAL
                } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
                    link = process.env.REACT_APP_CDN_API_URL_DEVELOPMENT
                } else if (process.env.REACT_APP_MODE === 'LIVE') {
                    link = process.env.REACT_APP_CDN_API_URL_LIVE
                }

                fetch(link + 'upload/', requestOptions)
                    .then((response) => response.text())
                    .then((result) => {

                        fetch(ApiURL() + 'upload/secure/', {
                            method: 'POST',
                            body: result,
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + token()
                            }
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                newFile1 = data.id
                                let formfile2 = (document.getElementById('fileuploadAttachment2') as HTMLInputElement).files
                                if (formfile2 !== null) {
                                    for (let f = 0; f < formfile2?.length; f++) {
                                        let formDatas = new FormData()
                                        allowedType = [
                                            {type: 'application/pdf', size: 5000000}
                                        ]
                                        let checkType2 = 0
                                        let checkSize2 = 0
                                        if (allowedType !== undefined && allowedType.length > 0) {
                                            for (let type = 0; type < allowedType.length; type++) {
                                                // Überprüfung des Dateiformats
                                                if (allowedType[type].type === formfile2[f].type) {
                                                    checkType2 = checkType2 + 1;
                                                }
                                                // Überprüfung der Dateigröße
                                                if (formfile2[f].size <= allowedType[type].size) {  // Fixiere den Vergleich (kleiner gleich)
                                                    checkSize2 = checkSize2 + 1;
                                                }
                                            }
                                        }
                                        if (checkType2 < 1) {
                                            alert(`Invalid file format: ${formfile2[f].name}. Only PDF files are allowed.`);
                                            return; // Funktion abbrechen
                                        }
                                        if (checkSize2 < 1) {
                                            alert(`File too large: ${formfile2[f].name}. Maximum allowed size is 5MB.`);
                                            return; // Funktion abbrechen
                                        }

                                        formDatas.append('customer', process.env.REACT_APP_CDN_CLIENT as string)
                                        formDatas.append('context', 'secure')
                                        // @ts-ignore
                                        formDatas.append(`file${0}`, formfile2[f])
                                        var requestOptions = {
                                            method: 'POST',
                                            body: formDatas
                                        }
                                        let link
                                        if (process.env.REACT_APP_MODE === 'LOCAL') {
                                            link = process.env.REACT_APP_CDN_API_URL_LOCAL
                                        } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
                                            link = process.env.REACT_APP_CDN_API_URL_DEVELOPMENT
                                        } else if (process.env.REACT_APP_MODE === 'LIVE') {
                                            link = process.env.REACT_APP_CDN_API_URL_LIVE
                                        }

                                        fetch(link + 'upload/', requestOptions)
                                            .then((response) => response.text())
                                            .then((result) => {

                                                fetch(ApiURL() + 'upload/secure/', {
                                                    method: 'POST',
                                                    body: result,
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        Authorization: 'Bearer ' + token()
                                                    }
                                                })
                                                    .then((response) => response.json())
                                                    .then((data) => {
                                                        newFile2 = data.id
                                                        let datas = new FormData(e.target);
                                                        let value = Object.fromEntries(datas.entries());
                                                        delete value.file1;
                                                        delete value.file2;
                                                        let files = {file1: newFile1, file2: newFile2}
                                                        let merged = {...value, ...files};
                                                        let raw = JSON.stringify(merged);
                                                        let root = ApiURL();
                                                        let requestOptionss = {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-Type': 'application/json',
                                                                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)
                                                            },
                                                            body: raw,
                                                        };
                                                        fetch(root + "Conference/CfS/0/", requestOptionss)
                                                            .then(response => response.text())
                                                            .then(result => Notification({
                                                                rewrite: true,
                                                                landingpages: '/',
                                                                type: 'success',
                                                                mode: 'classic',
                                                                title: {text: 'Success'},
                                                                text: {text: 'Saved successfully'},
                                                                insert: 'center right',
                                                                duration: 4
                                                            }))
                                                            .catch(error => Notification({
                                                                rewrite: true,
                                                                landingpages: '/',
                                                                type: 'danger',
                                                                mode: 'classic',
                                                                title: {text: 'Error'},
                                                                text: {text: 'Sorry, an error has occurred'},
                                                                insert: 'center right',
                                                                duration: 4
                                                            }));
                                                        let Button = document.getElementById('sendButton') as HTMLButtonElement;
                                                        Button.setAttribute('disabled', 'disabled');
                                                        Button.style.background = 'gray'
                                                    })
                                                    .catch((e: any) => {
                                                        console.log(e)
                                                    })


                                            })
                                            .catch((e: any) => {
                                                console.log(e)
                                            })
                                    }
                                }
                            })
                            .catch((e: any) => {
                                console.log(e)
                            })


                    })
                    .catch((e: any) => {
                        console.log(e)
                    })
            }
        }

    }

    const deleteElement = (e: any) => {
        let element = e.target.parentElement.parentElement
        element.remove();
    }

    const addElement = (row: any = false, col: any, id: any, addClass: any = '') => {
        let counter;
        if (id === 'Authors') {
            authorint++;
            counter = authorint;
        }
        if (row === true) {
            let newElement = (<>
                <div className={''}><InputGroup className={"mb-3 inputGroupExtra form-control " + addClass}>
                    <Form.Control
                        name={id + '' + counter}
                        placeholder="Lorem Ipsum"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        className={'inputNoBorder'}
                    />
                    <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                     onClick={() => addElement(row, col, id, addClass)}>
                                <span className="material-icons">
                                    add
                                </span>
                    </InputGroup.Text>
                    <InputGroup.Text id="basic-addon2" className={'IconPlus'}>
                    <span
                        className="material-symbols-outlined" style={{color: '#317391'}}
                        onClick={(e) => deleteElement(e)}>
                    delete
                    </span>
                    </InputGroup.Text>

                </InputGroup></div>
            </>);

            let temp = document.createElement('div');
            temp.classList.add('row')
            ReactDOM.render(newElement, temp);
            let ele = document.getElementById(id);
            if (ele !== null) {
                ele.appendChild(temp)
            }
        } else {
            let newElement = (<><InputGroup className={"mb-3 inputGroupExtra form-control " + addClass}>
                <Form.Control
                    name={id + '' + counter}
                    placeholder="Lorem Ipsum"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={'inputNoBorder'}
                />
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                 onClick={() => addElement(row, col, id, addClass)}>
                                <span className="material-icons">
                                    add
                                </span>
                </InputGroup.Text>
                <InputGroup.Text id="basic-addon2" className={'IconPlus'}>
                 <span
                     className="material-symbols-outlined" style={{color: '#317391'}} onClick={(e) => deleteElement(e)}>
                    delete
                    </span>
                </InputGroup.Text>
            </InputGroup></>);
            let temp = document.createElement('div');
            temp.classList.add('col-' + col)
            ReactDOM.render(newElement, temp);
            console.log("id: " + id)
            console.log(temp)
            let ele = document.getElementById(id);
            if (ele !== null) {
                ele.appendChild(temp)
            }
        }

    }


    if (Level.length > 0 && PresentationType.length > 0 && AllWorkshops.length > 0) {

        let optionLevel = [];

        let optionPresentationType = [];

        for (let l = 0; l < Level.length; l++) {
            optionLevel.push(
                <>
                    <option value={Level[l].id}>{Level[l].type}</option>
                </>
            )
        }

        for (let l = 0; l < PresentationType.length; l++) {
            optionPresentationType.push(
                <>
                    <option value={PresentationType[l].id}>{PresentationType[l].type}</option>
                </>
            )
        }

        if (process.env.REACT_APP_SESSION_SUBMISSION_ACTIVE !== undefined && process.env.REACT_APP_SESSION_SUBMISSION_ACTIVE === 'True') {
            return (<>
                <Row className={''}>
                    <Row>
                        <Col xs={4} md={4} lg={4} style={{
                            background: 'transparent linear-gradient(270deg, #0CB4CE 0%, #317391 100%) 0% 0% no-repeat padding-box',
                            color: 'white',
                            maxHeight: '840px'
                        }}>
                            <br/>
                            <p className={'text-center'}><img
                                src={'https://cdn.wum-solution.de//icymareKonfDev/2023/12/18/jU/ICYMARE-Logo-wide-screen.png'}/>
                            </p>
                            <br/>
                            <h2>{IntlMessageValue('de', 'icy.session.submission.text', Store)}</h2>
                            <p><img
                                src={'/assets/ICYMARE-2025-BREMERHAVEN_CallForSessions.jpg'}/>
                            </p>
                            <br/>
                            <p></p>
                        </Col>
                        <Col style={{
                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                            maxHeight: '840px',
                            overflowX: 'hidden',
                            overflowY: 'scroll'
                        }}> <Form id={'form01'} onSubmit={(e) => send(e)}>
                            <Row><h3>{IntlMessageValue('de', 'icy.session.holder.main', Store)}</h3></Row><Row>
                            {/*hidden field for year*/}
                            <Form.Control type="text" name={'year'} placeholder="Enter Title" defaultValue={2025}
                                          hidden={true}/>

                            <Col>
                                <Form.Control type="hidden" name={'UserId'} defaultValue={Store.user.id}/>
                                <Form.Group className="mb-3" controlId="Title">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control type="text" name={'academic_title'} placeholder="Enter Title"
                                                  translate="no"/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="FirstName">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" name={'FirstName'} readOnly={true}
                                                  placeholder="Enter first name" defaultValue={Store.user.first_name}/>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="LastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" name={'LastName'} readOnly={true}
                                                  placeholder="Enter last name" defaultValue={Store.user.last_name}/>
                                </Form.Group>
                            </Col>
                        </Row>
                            <Row>

                                <Col>
                                    <Form.Group className="mb-3" controlId="Emailaddress">
                                        <Form.Label>Email address:</Form.Label>
                                        <Form.Control type="email" name={'Emailaddress'} readOnly={true}
                                                      placeholder="Enter email adress" defaultValue={Store.user.email}/>
                                    </Form.Group>
                                </Col>
                                <Col className={"my-auto"}>
                                        <div className={"border rounded p-1 border-dark"}>{IntlMessageValue('de', 'icy.name.changes', Store)}
                                            <a href={"mailto:support@icymare.com"}>support@icymare.com</a>
                                        </div>
                                </Col>
                            </Row>
                            <Row><Col><Form.Group className="mb-3" controlId="Mainaffiliation">
                                <Form.Label className={"required"}>Main affiliation</Form.Label>
                                <Form.Control type="text" name={'mainAffiliation'} placeholder="Enter main affiliation"
                                              required={true}/>
                            </Form.Group></Col></Row>


                            <Row><h3>Education Level</h3>
                                <p>{IntlMessageValue('de', 'icy.session.ecr.expl', Store)}</p>
                            </Row> <Row>

                            <Col>
                                <Form.Label>Level</Form.Label>
                                <Form.Select aria-label="Default select example" name={'Level'} required={true}>
                                    {optionLevel}
                                </Form.Select>
                            </Col>
                            <Col><Form.Group className="mb-3" controlId="Year">
                                <Form.Label className={"required"}>Year</Form.Label>
                                <Form.Control type="number" name={'Year'} min="1900" max="2099" placeholder="Enter Year"
                                              required={true}/>
                            </Form.Group></Col>
                        </Row>

                            <Row>
                                <Form.Group className={"mb-3 border rounded pt-2 pb-2 "+(valueSessionTitle === '' ? 'border-danger ' : 'border-success ')} controlId="PresentationTitle">
                                    <Form.Label><h3 className={"required"}>{IntlMessageValue('de', 'icy.session.title', Store)}</h3></Form.Label>
                                    <SunEditor
                                        key={'test'}
                                        name={'Title'}
                                        placeholder="Enter Session title"
                                        setContents={valueSessionTitle}
                                        onChange={(valuePresentationTitle) => setEditorPresentationTitleContent(valuePresentationTitle)}
                                        setOptions={{
                                            buttonList: SunEditorConfig(),
                                            attributesWhitelist: {
                                                div: 'id',
                                                p: 'id',
                                                h: 'id'
                                            }
                                        }}
                                        height={'50px'}
                                    />
                                </Form.Group>
                            </Row>

                            <Row><h3>{IntlMessageValue('de', 'icy.session.additional.hosts.headline', Store)}</h3>
                                <p>{IntlMessageValue('de', 'icy.session.additional.hosts', Store)}</p>
                            </Row>

                            <Authors author={undefined} readonly={false} addElement={addElement}/>


                            <br/>
                            {/*<p>Please fill in all affiliations of the contributing authors according to the numbers stated above. Use the + for adding more arrays (click on the field).</p>
                    <Row id={'Affiliations'}>
                        <Row>
                        <div className={'col-12'}>
                        <InputGroup className="mb-3 inputGroupExtra form-control">
                            <InputGroup.Text>
                                Host:
                            </InputGroup.Text>
                            <Form.Select className={"col-1 AffiliationAuthor"} name={'affiliationText0'} aria-label="Default select example" id={'AffiliationAuthor'}  required={true}>
                                <option value={1}>1</option>
                            </Form.Select>
                            <InputGroup.Text>
                                Affiliations:
                            </InputGroup.Text>
                            <Form.Control
                                name={'Affiliations0'}
                                placeholder="Affiliations"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                className={'inputNoBorder'}
                                required={true}
                            />
                            <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                             onClick={()=>addElement(true,'12','Affiliations','')}>
                                 <span className="material-icons">
                                    add
                                </span>
                            </InputGroup.Text>
                        </InputGroup>
                        </div>
                        </Row>
                    </Row>*/}
                            <br/>
                            <Row>
                                <h3  className={"required"}>
                                    {IntlMessageValue('de', 'icy.session.workshop.headline', Store)}
                                </h3>
                            </Row>
                            <WorkshopSelect WorkshopList={AllWorkshops} store={Store}/>
                            <br/>
                            <Row><h3>Social Media Handles</h3>
                                <p>Please provide us with your social media handles (“@...”) to enable us to tag
                                    you:</p>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="Twitter">
                                        <Form.Label>Twitter</Form.Label>
                                        <Form.Control type="text" name={'Twitter'} placeholder="Enter Twitter account"/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="Instagram">
                                        <Form.Label>Instagram</Form.Label>
                                        <Form.Control type="text" name={'Instagram'}
                                                      placeholder="Enter Instagram account"/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="Facebook">
                                        <Form.Label>Facebook</Form.Label>
                                        <Form.Control type="text" name={'Facebook'}
                                                      placeholder="Enter Facebook account"/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="LinkedIn">
                                        <Form.Label>LinkedIn</Form.Label>
                                        <Form.Control type="text" name={'LinkedIn'}
                                                      placeholder="Enter LinkedIn account"/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="Mastodon">
                                        <Form.Label>Mastodon</Form.Label>
                                        <Form.Control maxLength={100} type="text" name={'Mastodon'}
                                                      placeholder="Enter Mastodon account"/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="Bluesky">
                                        <Form.Label>Bluesky</Form.Label>
                                        <Form.Control maxLength={100} type="text" name={'Bluesky'}
                                                      placeholder="Enter BlueSky account"/>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row><h4>Social Media Handles (Institution)</h4>
                                <p>Please provide us with the social media handles of your first affiliation to enable
                                    us to tag the institution (preferably “@...”, otherwise also “#...” is
                                    possible):</p>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="InstitutionTwitter">
                                        <Form.Label>Twitter</Form.Label>
                                        <Form.Control type="text" name={'InstitutionTwitter'}
                                                      placeholder="Enter Twitter account"/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="InstitutionInstagram">
                                        <Form.Label>Instagram</Form.Label>
                                        <Form.Control type="text" name={'InstitutionInstagram'}
                                                      placeholder="Enter Instagram account"/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="InstitutionFacebook">
                                        <Form.Label>Facebook</Form.Label>
                                        <Form.Control type="text" name={'InstitutionFacebook'}
                                                      placeholder="Enter Facebook account"/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="InstitutionLinkedIn">
                                        <Form.Label>LinkedIn</Form.Label>
                                        <Form.Control type="text" name={'InstitutionLinkedIn'}
                                                      placeholder="Enter LinkedIn account"/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="InstitutionMastodon">
                                        <Form.Label>Mastodon</Form.Label>
                                        <Form.Control maxLength={100} type="text" name={'InstitutionMastodon'}
                                                      placeholder="Enter Mastodon account"/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="InstitutionBluesky">
                                        <Form.Label>Bluesky</Form.Label>
                                        <Form.Control maxLength={100} type="text" name={'InstitutionBluesky'}
                                                      placeholder="Enter Bluesky account"/>
                                    </Form.Group>
                                </Col>
                            </Row>


                            {/*
                    <Row><h3>Keywords</h3>
                        <p>Please provide max. 5 keywords about your presentation topic. Terms should not be already part the title but should also not be too general.</p>
                    </Row>
                    <Row id={'Keywords'}>
                        <Row>
                        <Col>
                            <InputGroup className="mb-3 inputGroupExtra form-control">
                                <InputGroup.Text>
                                    1
                                </InputGroup.Text>
                                <Form.Control
                                    name={'Keywords0'}
                                    placeholder="Keyword"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    className={'inputNoBorder'}
                                    required={true}
                                />
                                <InputGroup.Text id="basic-addon2" className={'IconPlus'}
                                                 onClick={()=>addElement(true,'12','Keywords','',true)}>
                                     <span className="material-icons">
                                    add
                                </span>
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                        </Row>
                    </Row>
*/}

                            <Row><h3 className={"required"}>Abstract</h3>
                                <p>{IntlMessageValue('de', 'icy.session.abstract.expl', Store)}</p>
                            </Row>
                            <Row>
                                <Form.Group className={"mb-3 border rounded pt-2 pb-2 "+(valueAbstract === '' ? 'border-danger ' : 'border-success ')} controlId="Abstract">
                                    <SunEditor
                                        key={'test'}
                                        name={'Abstract'}
                                        setContents={valueAbstract}
                                        onChange={(valueAbstract) => setEditorAbstractContent(valueAbstract)}
                                        placeholder="Enter Abstract"
                                        setOptions={{
                                            buttonList: SunEditorConfig(),
                                            attributesWhitelist: {
                                                div: 'id',
                                                p: 'id',
                                                h: 'id'
                                            }
                                        }}
                                        height={'250px'}
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <h3>{IntlMessageValue('de', 'icy.session.mandatory.documents', Store)}</h3>
                                <Col>
                                    <Form.Group controlId="formFileMultiple" className="mb-3">
                                        <Form.Label className={"required"}>CV</Form.Label>
                                        <Form.Control id={'fileuploadAttachment1'} name={'file1'} type="file"
                                                      accept=".pdf"/>
                                    </Form.Group>
                                    <Form.Group controlId="formFileMultiple" className="mb-3">
                                        <Form.Label className={"required"}>{IntlMessageValue('de', 'icy.session.letter.headline', Store)}</Form.Label>
                                        <Form.Control id={'fileuploadAttachment2'} name={'file2'} type="file"
                                                      accept=".pdf"/>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    {back}
                                    <Button variant="primary" className={'float-end'} id={'sendButton'} type="submit"
                                            style={{
                                                background: (valueAbstract === '' || valueSessionTitle === '' ? 'lightgray' : '#307593 0% 0% no-repeat padding-box'),
                                                boxShadow: '0px 3px 6px #00000029',
                                                borderRadius: '34px',
                                                marginBottom: '20px',
                                                opacity: 1,
                                                float: 'right',
                                                height: '67px',
                                                width: '350px'
                                            }} disabled={(valueAbstract === '' || valueSessionTitle === '' ? true : false)}>
                        <span style={{
                            font: 'normal normal bold 30px/40px Segoe UI',
                            letterSpacing: '0px',
                            color: '#FFFFFF',
                            opacity: 1
                        }}>
                             Send
                        </span>
                                    </Button>
                                    <br/>
                                    <br/>
                                </Col>
                            </Row>
                        </Form></Col>
                    </Row>

                </Row>
            </>)
        } else {
            return (
                <>
                    <h1>Session submission is closed!</h1>
                    <p>Sorry, but session submission is closed already. Please consider to hand in an abstract for a
                        presentation or Talk!</p>
                </>
            );
        }
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }

}

export default AbstractNew;
